import classnames from 'classnames';
import React from 'react';
import { useSelector } from 'react-redux';
import { quizes, teamByIds } from 'services/data';
import { Some } from 'ts-matches';
import { RootState } from 'types/store';
import styles from './Name.module.scss';

interface Props {
  memberId: string;
  type: 'answers' | 'questions';
}

const Name: React.FC<Props> = ({ memberId, type }) => {
  const quizId = useSelector((state: RootState) => state.app.quizId);
  const field = quizes[quizId][type];

  return Some.of(teamByIds[memberId])
    .map(member => (
      <div
        className={classnames(styles.container, styles[type])}
        key={member.id}
      >
        <div className={classnames(styles.label, styles[type])}>
          {member[field]}
        </div>
      </div>
    ))
    .defaultTo(<></>);
};

export default Name;
