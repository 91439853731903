import classnames from 'classnames';
import { NEXT_QUESTION_TIME, SCORE_MULTIPLIER } from 'config';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import styles from './Counter.module.scss';

interface Props {
  value: number;
}

const Clock: React.FC<Props> = ({ value }) => {
  const [moment, setMoment] = useState(value);
  const [animated, setAnimated] = useState(false);

  const startTimeRef = useRef(Date.now());
  const lastValueRef = useRef(value);

  const easeOutSine = (x: number): number => 1 - Math.pow(1 - x, 4);

  const renderMoment = (amount: number, pad: number = 5): string =>
    String(Math.round(amount * SCORE_MULTIPLIER)).padStart(pad, '0');

  const handleAnimation = useCallback(() => {
    const fraction = Math.min(
      (Date.now() - startTimeRef.current) / NEXT_QUESTION_TIME,
      1
    );
    const easedFraction = easeOutSine(fraction);

    const newMoment =
      lastValueRef.current + (value - lastValueRef.current) * easedFraction;
    setMoment(newMoment);

    if (fraction < 1) {
      window.requestAnimationFrame(handleAnimation);
      return;
    }

    setAnimated(false);
    lastValueRef.current = value;
  }, [value]);

  useEffect(() => {
    startTimeRef.current = Date.now();

    setAnimated(true);
    handleAnimation();
  }, [value, handleAnimation]);

  const modifiers = {
    [styles.animated]: animated,
  };

  return (
    <code className={classnames(styles.score, modifiers)}>
      {renderMoment(moment)}
      <span role="img" aria-label="score" className={styles.scoreIcon}>
        💰
      </span>
    </code>
  );
};

export default memo(Clock);
