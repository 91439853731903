export enum AppActionType {
  APP_QUESTION_START = 'APP_QUESTION_START',
  APP_ANSWER_SUCCESS = 'APP_ANSWER_SUCCESS',
  APP_ANSWER_FAILED = 'APP_ANSWER_FAILED',
  APP_SCORE_ADD = 'APP_SCORE_ADD',
  APP_QUEUE_RESET = 'APP_QUEUE_RESET',
  APP_GAME_OVER = 'APP_GAME_OVER',
  APP_GAME_RESET = 'APP_GAME_RESET',
  APP_PLAYER_RESET = 'APP_PLAYER_RESET',
  APP_PLAYER_SET = 'APP_PLAYER_SET',
  COMMON_RESET = 'COMMON_RESET',
}
