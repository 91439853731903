import Button from 'common/Button';
import HighScore from 'common/HighScore';
import Players from 'common/Players';
import Title from 'common/Title';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { teamByIds } from 'services/data';
import { handleGameRestart, playerReset } from 'store/app/action';
import { Some } from 'ts-matches';
import { RootState } from 'types/store';
import styles from './Start.module.scss';

const Start: React.FC = () => {
  const dispatch = useDispatch();

  const player = useSelector((state: RootState) => state.app.player);

  const handleStart = useCallback(() => {
    dispatch(handleGameRestart());
  }, [dispatch]);

  const handlePlayerReset = useCallback(() => {
    dispatch(playerReset());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      {Some.of(player)
        .map(some => (
          <>
            <Title label={`👋 Cześć, ${teamByIds[some].name}! 👋`} />
            <HighScore />
            <div className={styles.buttons}>
              <Button onClick={handlePlayerReset} secondary={true}>
                Zmień postać
              </Button>
              <Button onClick={handleStart}>Start</Button>
            </div>
          </>
        ))
        .defaultTo(
          <>
            <Title label="👀 Znajdź swoją postać 👀" />
            <Players />
            <Button onClick={handleStart}>Graj incognito</Button>
          </>
        )}
    </div>
  );
};

export default Start;
