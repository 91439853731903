import { useRef, useState } from 'react';
import { ApiConnection, defaultApiConnectionObject } from './ApiConnection';
import { ApiConnectionObject } from './types';

export const useApi = <T>(
  dataName: string
): [ApiConnectionObject<T>, ApiConnection<T>] => {
  const [apiData, setApiData] = useState(defaultApiConnectionObject);
  const apiConnection = useRef(new ApiConnection<T>(dataName, setApiData));

  return [apiData, apiConnection.current];
};
