import classnames from 'classnames';
import React from 'react';
import styles from './Loader.module.scss';

const Loader: React.FC = () => (
  <div className={classnames(styles.container)}>
    <span role="img" aria-label="loading">
      ⏳ ⏳ ⏳ ⏳ ⏳
    </span>
  </div>
);

export default Loader;
