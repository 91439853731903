import logo from 'assets/sml_sign_rgb.svg';
import classnames from 'classnames';
import React from 'react';
import styles from './Title.module.scss';

interface Props {
  label?: string;
  noHeader?: boolean;
}

const Title: React.FC<Props> = ({ label, noHeader }) => (
  <div className={classnames(styles.container)}>
    <img src={logo} alt="SoftwareMill" className={styles.logo} />
    {!noHeader && <div className={styles.title}>Gra w Ryje</div>}

    {label && (
      <div key="label" className={styles.label}>
        {label}
      </div>
    )}
  </div>
);

export default Title;
