import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { teamIds } from 'services/data';
import { playerSet } from 'store/app/action';
import { TeamMemberId } from 'types/team';
import Item from './Item';
import styles from './Players.module.scss';

const Players: React.FC = () => {
  const dispatch = useDispatch();

  const handleChoose = useCallback(
    (memberId: TeamMemberId) => {
      dispatch(playerSet(memberId));
    },
    [dispatch]
  );

  return (
    <div className={styles.container}>
      {teamIds.map((item, index) => (
        <Item
          onChoose={handleChoose}
          key={index}
          memberId={item}
          index={index + 1}
        />
      ))}
    </div>
  );
};

export default Players;
