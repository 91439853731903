import classnames from 'classnames';
import React, { memo } from 'react';
import { teamByIds } from 'services/data';
import { Some } from 'ts-matches';
import styles from './Face.module.scss';

interface Props {
  memberId: string;
  type: 'answers' | 'questions' | 'players';
}

const Face: React.FC<Props> = ({ memberId, type }) =>
  Some.of(teamByIds[memberId])
    .map(member => (
      <img
        key={member.id}
        src={member.image}
        className={classnames(styles.image, styles[type])}
        alt="face"
      />
    ))
    .defaultTo(<></>);

export default memo(Face);
