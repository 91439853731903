import classnames from 'classnames';
import Button from 'common/Button';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleAnswer } from 'store/app/action';
import { RootState } from 'types/store';
import styles from './Answer.module.scss';

interface Props {
  correct: boolean;
  timeUp: boolean;
  bindedKeys: string[];
}

const Answer: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  correct,
  timeUp,
  bindedKeys,
}) => {
  const [clicked, setClicked] = useState(false);

  const dispatch = useDispatch();
  const revealed = useSelector((state: RootState) => state.app.revealed);

  useEffect(() => {
    if (!revealed) {
      setClicked(false);
    }
  }, [revealed]);

  const handleKeyDown = useCallback(
    ({ key }: KeyboardEvent) => {
      if (!bindedKeys.includes(key)) {
        return;
      }

      if (revealed) {
        return;
      }

      dispatch(handleAnswer(correct));
      setClicked(true);
    },
    [revealed, correct, dispatch, bindedKeys]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleClick = useCallback(() => {
    if (revealed) {
      return;
    }

    dispatch(handleAnswer(correct));
    setClicked(true);
  }, [revealed, correct, dispatch]);

  const modifiers = {
    [styles.correct]: correct,
    [styles.revealed]: revealed,
    [styles.clicked]: clicked,
    [styles.timeUp]: timeUp,
  };

  return (
    <div className={classnames(styles.container, modifiers)}>
      <Button
        onClick={handleClick}
        clicked={clicked}
        className={classnames(styles.button, modifiers)}
      >
        {children}
      </Button>
    </div>
  );
};

export default Answer;
