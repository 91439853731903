import classnames from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import styles from './Bar.module.scss';

interface Props {
  value: number;
  animated?: boolean;
  pointsAnimation?: boolean;
  role: 'timer' | 'energy';
}

const Bar: React.FC<Props> = ({ value, animated, role, pointsAnimation }) => {
  const cappedValue = Math.min(1, Math.max(0, value));
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    if (!animated) {
      return;
    }
    setTriggered(false);
    window.requestAnimationFrame(() => setTriggered(true));
  }, [value, animated]);

  const emojis = {
    energy: '💚',
    timer: '⏱️',
  };

  return (
    <div className={styles.container}>
      <span className={styles.icon} role="img" aria-label={role}>
        {emojis[role]}
      </span>
      <div className={styles.bar}>
        <div
          className={classnames(styles.progress, {
            [styles.animated]: animated,
          })}
          style={{ transform: `scaleX(${cappedValue})` }}
        >
          <div className={classnames(styles.fill, styles[role])} />
          <div
            className={classnames(styles.fill, styles.red, {
              [styles.animated]: animated,
              [styles.triggered]: triggered,
            })}
            style={{ opacity: 1 - cappedValue }}
          />
          {pointsAnimation && (
            <div className={classnames(styles.fill, styles.pointsAnimation)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Bar);
