import React from 'react';
import { teamIds } from 'services/data';
import { TeamMemberId } from 'types/team';
import Item from './Item';
import styles from './List.module.scss';
import { DataItem } from './types';

interface Props {
  player?: TeamMemberId;
  data: DataItem[];
}

const List: React.FC<Props> = ({ data, player }) => {
  const filteredData = data.filter(({ name }) => teamIds.includes(name));
  const richData =
    player && filteredData.findIndex(({ name }) => name === player) === -1
      ? [...filteredData, { name: player, score: 0 } as DataItem]
      : filteredData;

  return (
    <div className={styles.container}>
      {richData.map((item, index) => (
        <Item key={index} data={item} player={player} index={index + 1} />
      ))}
    </div>
  );
};

export default List;
