import axios, { isAxiosError, AxiosError, AxiosRequestConfig } from 'axios';
import matches, { Either, Left, Right } from 'ts-matches';
import { ApiError } from './types';

export const apiUrl: string = process.env.REACT_APP_API_URL || '/api/v1/';
export const apiMock: boolean = process.env.REACT_APP_API_MOCK === 'true';

const globalConfig: AxiosRequestConfig = {};

const normalizeError = (error: Error | AxiosError): ApiError => {
  if (isAxiosError(error) && error.response !== undefined) {
    const {
      response: { status, statusText, data },
    } = error;

    // @ts-ignore
    const message = matches<string>(data)
      .when(matches.object, () => data.message)
      .when(matches.string, (msg) => msg)
      .defaultTo(statusText);

    return {
      code: status,
      message,
    };
  }
  return { code: 0, message: error.message };
};

export const get = async <T>(
  endpoint: string,
  config?: AxiosRequestConfig
): Promise<Either<ApiError, T>> => {
  try {
    // await new Promise(resolve => setTimeout(resolve, 1000));

    const { data } = await axios.get(`${apiUrl}${endpoint}`, {
      ...globalConfig,
      ...config,
    });
    return Right.of(data);
  } catch (error) {
    return Left.of(normalizeError(error as Error));
  }
};

export const post = async <T>(
  endpoint: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<Either<ApiError, T>> => {
  try {
    // await new Promise(resolve => setTimeout(resolve, 1000));

    const response = await axios.post(`${apiUrl}${endpoint}`, data, {
      ...globalConfig,
      ...config,
    });

    return Right.of(response.data);
  } catch (error) {
    return Left.of(normalizeError(error as Error));
  }
};
