export const backToQueue = <T>(queue: T[], count: number = 1): T[] => [
  ...queue.slice(count),
  ...queue.slice(0, count),
];

export const shuffle = <T>(queue: T[]): T[] => {
  const shuffled = [...queue].sort(() => Math.random() - 0.5);

  return shuffled;
};

export default { backToQueue };
