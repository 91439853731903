import {
  BASE_TIME,
  LIVES,
  LIVES_FACTOR,
  MAX_OPTIONS,
  QUEUE_LENGTH,
} from 'config';
import { teamIds } from 'services/data';
import { Mode } from 'types/game';
import { ReduxAction } from 'types/store';
import { TeamMemberId } from 'types/team';
import { shuffle } from 'utils/array';
import { AppActionType } from './types';

export interface AppState {
  lives: number;
  mode: Mode;
  options: TeamMemberId[];
  player?: TeamMemberId;
  queue: TeamMemberId[];
  question: TeamMemberId;
  quizId: number;
  revealed: boolean;
  roundTime: number;
  score: number;
  team: TeamMemberId[];
}

const getStoragePlayer = () => {
  const storagedPlayer = window.localStorage.getItem('player');
  if (storagedPlayer && teamIds.includes(storagedPlayer)) {
    return storagedPlayer;
  }
  return undefined;
};

const baseRoundTime = BASE_TIME * teamIds.length;

export const initialState: AppState = {
  lives: LIVES,
  mode: Mode.START,
  options: teamIds.slice(0, MAX_OPTIONS),
  player: getStoragePlayer(),
  question: teamIds[0],
  queue: teamIds.slice(0, QUEUE_LENGTH),
  quizId: 0,
  revealed: false,
  roundTime: baseRoundTime,
  score: 0,
  team: teamIds.slice(QUEUE_LENGTH),
};

export default (
  state: AppState = initialState,
  { type, payload }: ReduxAction
): AppState => {
  const restOfQueue = state.queue.filter(member => member !== state.question);
  const [pendingMember, ...restOfTeam] = state.team;
  const shuffledTeamIds = shuffle(teamIds).filter(
    member => member !== state.player
  );

  switch (type) {
    case AppActionType.APP_QUESTION_START:
      return {
        ...state,
        mode: Mode.PENDING,
        options: payload.options,
        question: payload.question,
        quizId: payload.quizId,
        revealed: false,
        roundTime: payload.roundTime,
      };

    case AppActionType.APP_ANSWER_SUCCESS:
      return {
        ...state,
        mode: Mode.SUCCESS,
        queue: [...restOfQueue, pendingMember],
        revealed: true,
        team: [...restOfTeam, state.question],
      };

    case AppActionType.APP_ANSWER_FAILED:
      return {
        ...state,
        lives:
          state.lives - Math.max(Math.min(state.score / LIVES_FACTOR, 1), 0.01),
        mode: Mode.FAILED,
        queue: [...restOfQueue, state.question],
        revealed: true,
      };

    case AppActionType.APP_SCORE_ADD:
      return {
        ...state,
        score: state.score + payload.score,
      };

    case AppActionType.APP_QUEUE_RESET:
      return {
        ...state,
        queue: shuffledTeamIds.slice(0, QUEUE_LENGTH),
        team: shuffledTeamIds.slice(QUEUE_LENGTH),
      };

    case AppActionType.APP_GAME_OVER:
      return {
        ...state,
        mode: Mode.END,
      };

    case AppActionType.APP_GAME_RESET:
      return {
        ...state,
        lives: LIVES,
        queue: shuffledTeamIds.slice(0, QUEUE_LENGTH),
        quizId: 0,
        roundTime: baseRoundTime,
        score: 0,
        team: shuffledTeamIds.slice(QUEUE_LENGTH),
      };

    case AppActionType.APP_PLAYER_SET:
      return {
        ...state,
        player: payload.player,
      };

    case AppActionType.APP_PLAYER_RESET:
      return {
        ...state,
        mode: Mode.START,
        player: undefined,
      };

    case AppActionType.COMMON_RESET:
      return { ...initialState };

    default:
      return state;
  }
};
