import classnames from 'classnames';
import Face from 'common/Face';
import React from 'react';
import { teamByIds } from 'services/data';
import { TeamMemberId } from 'types/team';
import styles from './List.module.scss';
import { DataItem } from './types';

interface Props {
  data: DataItem;
  index: number;
  player?: TeamMemberId;
}

const Item: React.FC<Props> = ({
  index,
  data: { name, score, victories },
  player,
}) => {
  const modifiers = {
    [styles.leader]: index === 1,
    [styles.selected]: player === name,
  };

  const medals = ['🥇', '🥈', '🥉'];

  return (
    <div className={classnames(styles.row, modifiers)}>
      <div className={classnames(styles.cell, styles.index)}>
        <span className={classnames(styles.text, styles.index, modifiers)}>
          {medals[index - 1] || `${index}.`}
        </span>
      </div>
      <div className={classnames(styles.cell, styles.face)}>
        <Face memberId={name} type="players" />
      </div>
      <div className={classnames(styles.cell, styles.name)}>
        <span className={classnames(styles.text, styles.name, modifiers)}>
          {teamByIds[name].fullName} {index === 1 && '👑'}
        </span>
      </div>
      {victories > 0 && (
        <div className={classnames(styles.cell, styles.victories)}>
          <code
            className={classnames(styles.text, styles.victories, modifiers)}
          >
            {victories}
          </code>
          <span
            role="img"
            aria-label="victories"
            className={classnames(styles.icon, styles.victories)}
          >
            👑
          </span>
        </div>
      )}
      <div className={classnames(styles.cell, styles.score)}>
        <code className={classnames(styles.text, styles.score, modifiers)}>
          {String(score).padStart(5, '0')}
        </code>
        <span
          role="img"
          aria-label="score"
          className={classnames(styles.icon, styles.score)}
        >
          💰
        </span>
      </div>
    </div>
  );
};

export default Item;
