import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'types/store';
import styles from './Question.module.scss';

interface Props {
  questionTime: number;
}

const Question: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  questionTime,
}) => {
  const [scale, setScale] = useState(1);
  const revealed = useSelector((state: RootState) => state.app.revealed);
  const mode = useSelector((state: RootState) => state.app.mode);
  const roundTime = useSelector((state: RootState) => state.app.roundTime);

  const revealedRef = useRef(revealed);
  const questionTimeRef = useRef(questionTime);

  const handleAnimation = useCallback(() => {
    setScale(
      1 + Math.pow((Date.now() - questionTimeRef.current) / roundTime, 2) / 4
    );

    if (!revealedRef.current) {
      window.requestAnimationFrame(handleAnimation);
    }
  }, [roundTime]);

  useEffect(() => {
    revealedRef.current = revealed;
    questionTimeRef.current = questionTime;

    if (!revealed) {
      handleAnimation();
    }
  }, [questionTime, revealed, handleAnimation]);

  const modifiers = {
    [styles.revealed]: revealed,
  };

  return (
    <div
      className={classnames(styles.container, styles[mode], modifiers)}
      style={{ transform: `scale(${scale})` }}
    >
      {children}
    </div>
  );
};

export default Question;
