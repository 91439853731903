import { AxiosRequestConfig } from 'axios';
import { Maybe, None, Some } from 'ts-matches';
import { AppError } from 'types/error';
import { get, post } from './service';
import { ApiConnectionObject, ApiStatus } from './types';

type DataCallBack<T> = (apiConnectionObject: ApiConnectionObject<T>) => void;

export const defaultApiConnectionObject: ApiConnectionObject = {
  data: None.of,
  error: None.of,
  name: 'default',
  status: ApiStatus.IDLE,
};

export class ApiConnection<T> {
  public error: Maybe<AppError> = None.of;
  public status: ApiStatus = ApiStatus.IDLE;
  public data: Maybe<T> = None.of;
  public name: string;

  private dataCallback: DataCallBack<T>;

  constructor(name: string, dataCallback: DataCallBack<T>) {
    this.name = name;
    this.dataCallback = dataCallback;
  }

  public fetchStart = () => {
    this.status = ApiStatus.PENDING;
    this.error = None.of;
    this.triggerCallback();
  };

  public getSuccess = (data: T) => {
    this.status = ApiStatus.SUCCESS;
    this.data = Some.of(data);
    this.triggerCallback();
  };

  public postSuccess = () => {
    this.status = ApiStatus.SUCCESS;
  };

  public fetchError = (error: AppError) => {
    this.error = Some.of(error);
    this.status = ApiStatus.ERROR;
    this.triggerCallback();
  };

  public get = async (endpoint: string) => {
    this.fetchStart();
    (await get<T>(endpoint)).fold({
      left: this.fetchError,
      right: this.getSuccess,
    });
  };

  public post = async (
    endpoint: string,
    data?: any,
    config?: AxiosRequestConfig
  ) => {
    this.fetchStart();
    (await post<T>(endpoint, data, config)).fold({
      left: this.fetchError,
      right: this.postSuccess,
    });
  };

  public triggerCallback = () =>
    this.dataCallback({
      data: this.data,
      error: this.error,
      name: this.name,
      status: this.status,
    });
}

export default ApiConnection;
