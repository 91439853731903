import Button from 'common/Button';
import React from 'react';
import { Some } from 'ts-matches';
import styles from './ErrorInfo.module.scss';

interface Props {
  errorMessage: string;
  onRetry?: () => void;
}

const ErrorInfo: React.FC<Props> = ({ errorMessage, onRetry }) => (
  <div className={styles.container}>
    <span role="img" aria-label="error">
      💀 💀 💀 💀 💀
    </span>
    <div className={styles.message}>{errorMessage}</div>
    {Some.of(onRetry)
      .map(just => (
        <Button key="retry" onClick={just}>
          Retry
        </Button>
      ))
      .defaultTo(<></>)}
  </div>
);

export default ErrorInfo;
