import Face from 'common/Face';
import React, { useCallback } from 'react';
import { TeamMemberId } from 'types/team';
import styles from './Players.module.scss';

interface Props {
  memberId: TeamMemberId;
  index: number;
  onChoose: (memberId: TeamMemberId) => void;
}

const Item: React.FC<Props> = ({ memberId, onChoose }) => {
  const handleClick = useCallback(() => {
    onChoose(memberId);
  }, [memberId, onChoose]);

  return (
    <div
      className={styles.item}
      onMouseDown={handleClick}
      onTouchStart={handleClick}
    >
      <Face memberId={memberId} type="players" />
    </div>
  );
};

export default Item;
