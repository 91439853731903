import { Maybe } from 'ts-matches';
import { AppError } from 'types/error';

export interface ListResponse<T> {
  records: T[];
  nextPageToken?: string;
}

export enum ApiStatus {
  IDLE = 'IDLE',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export interface ApiConnectionObject<T = any> {
  data: Maybe<T>;
  error: Maybe<AppError>;
  status: ApiStatus;
  name: string;
}

export interface ApiError {
  code: number;
  message: string;
}
