import Button from 'common/Button';
import HighScore from 'common/HighScore';
import Title from 'common/Title';
import { SCORE_MULTIPLIER } from 'config';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { teamByIds } from 'services/data';
import { handleGameRestart, playerReset } from 'store/app/action';
import { Maybe, Some } from 'ts-matches';
import { ScoreData } from 'types/score';
import { RootState } from 'types/store';
import styles from './End.module.scss';

const End: React.FC = () => {
  const dispatch = useDispatch();

  const score = useSelector((state: RootState) => state.app.score);
  const player = useSelector((state: RootState) => state.app.player);

  const handleStart = useCallback(() => {
    dispatch(handleGameRestart());
  }, [dispatch]);

  const handlePlayerReset = useCallback(() => {
    dispatch(playerReset());
  }, [dispatch]);

  const scoreRounded = Math.round(score * SCORE_MULTIPLIER);
  const scoreData = (Some.of(player).map(some => ({
    name: some,
    score: scoreRounded,
  })) as Maybe<ScoreData>).defaultTo(undefined);

  const invocation = Some.of(player)
    .map<string>(
      some =>
        `${teamByIds[some].name}, ${
          teamByIds[some].gender === 'f' ? 'zdobyłaś' : 'zdobyłeś'
        }`
    )
    .defaultTo('Zdobyłeś/aś');
  const applauses = [
    'mega',
    'super',
    'wypas',
    'dobrze',
    'znakomicie',
    'nieźle',
  ];
  const adverbs = [
    'naprawdę',
    'totalnie',
    'nieprawdopodobnie',
    'zdecydowanie',
    'oczywiście',
    'turbo',
  ];
  const emojis = ['😀', '😱', '👌', '👏', '💪', '👍', '🖖', '🎉'];
  const randomWord = (words: string[]): string =>
    words[Math.floor(Math.random() * words.length)];

  return (
    <div className={styles.container}>
      <Title label="💀 Game Over 💀" />
      <div className={styles.score}>
        <span className={styles.description}>{invocation} aż</span>
        <code className={styles.textScore}>
          {scoreRounded}
          <span role="img" aria-label="score" className={styles.icons}>
            💰
          </span>
        </code>
        <span className={styles.description}>
          ! To {randomWord(adverbs)} {randomWord(applauses)}!{' '}
          {randomWord(emojis)}
        </span>
      </div>
      <HighScore scoreData={scoreData} />
      <div className={styles.buttons}>
        <Button onClick={handlePlayerReset} secondary={true}>
          Zmień postać
        </Button>
        <Button onClick={handleStart}>Zagraj jeszcze raz</Button>
      </div>
    </div>
  );
};

export default End;
