import classnames from 'classnames';
import React from 'react';
import styles from './Button.module.scss';

interface Props {
  onClick: (...args: any) => any;
  clicked?: boolean;
  className?: string;
  secondary?: boolean;
}

const Button: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  secondary,
  onClick,
  clicked,
  className,
}) => (
  <button
    className={classnames(
      styles.container,
      { [styles.active]: !!clicked, [styles.secondary]: secondary },
      className
    )}
    onMouseDown={onClick}
    onTouchStart={onClick}
  >
    <span
      className={classnames(styles.label, {
        [styles.secondary]: secondary,
      })}
    >
      {children}
    </span>
  </button>
);

export default Button;
