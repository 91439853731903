export const MAX_OPTIONS = 3;
export const MIN_OPTIONS = 2;

export const BASE_TIME = 350;

export const NEXT_QUESTION_TIME = 2000;

export const LIVES = 3;
export const LIVES_FACTOR = 30;

export const SCORE_MULTIPLIER = 1000

export const QUEUE_LENGTH = 3;