import classnames from 'classnames';
import ErrorInfo from 'common/ErrorInfo';
import List from 'common/List';
import Loader from 'common/Loader';
import { ApiStatus, useApi } from 'domain/api';
import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import matches from 'ts-matches';
import { ScoreData, ScoreResponse } from 'types/score';
import { RootState } from 'types/store';
import styles from './HighScore.module.scss';

interface Props {
  scoreData?: ScoreData;
}

const HighScore: React.FC<Props> = ({ scoreData }) => {
  const [apiData, apiConnection] = useApi<ScoreResponse>('buildInfo');
  const { data, error, status } = apiData;
  const player = useSelector((state: RootState) => state.app.player);

  useEffect(() => {
    const synchronizeData = async () => {
      await matches(scoreData)
        .when(matches.nill, () => Promise.resolve())
        .defaultToLazy(() => apiConnection.post('score', scoreData));
      await apiConnection.get('score');
    };

    synchronizeData();
  }, [apiConnection, scoreData]);

  const handleRetry = useCallback(() => {
    apiConnection.get('score');
  }, [apiConnection]);

  return (
    <div className={classnames(styles.container)}>
      <>
        {data
          .map(some => <List player={player} key="table" data={some} />)
          .defaultTo(<></>)}
        {error
          .map(some => (
            <ErrorInfo
              key="error"
              errorMessage={some.message}
              onRetry={handleRetry}
            />
          ))
          .defaultTo(<></>)}

        {matches(status)
          .when(matches.literal(ApiStatus.PENDING), () => <Loader />)
          .defaultTo(<></>)}
      </>
    </div>
  );
};

export default HighScore;
