import quizesData from 'data/quizes.json';
import teamData from 'data/team.json';
import { Quiz } from 'types/quizes';
import { Team, TeamMember } from 'types/team';
import { shuffle } from 'utils/array';

export const team = (teamData as TeamMember[]).map(member => ({
  ...member,
  image: require(`data/images/${member.id}.jpg`),
}));

export const teamByIds: Team = team
  .map(member => ({ [member.id]: member }))
  .reduce((a, b) => ({ ...a, ...b }), {});

export const teamIds = shuffle(Object.keys(teamByIds));

export const quizes = shuffle(quizesData) as Quiz[];
