import End from 'common/End';
import Screen from 'common/Screen';
import Start from 'common/Start';
import React from 'react';
import { useSelector } from 'react-redux';
import { Mode } from 'types/game';
import { RootState } from 'types/store';
import styles from './App.module.scss';

const componentsMap = {
  [Mode.START]: Start,
  [Mode.PENDING]: Screen,
  [Mode.FAILED]: Screen,
  [Mode.SUCCESS]: Screen,
  [Mode.END]: End,
};

const App: React.FC = () => {
  const mode = useSelector((state: RootState) => state.app.mode);

  const Component = componentsMap[mode];

  return (
    <div className={styles.container}>
      <Component />
    </div>
  );
};

export default App;
