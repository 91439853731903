import classnames from 'classnames';
import Answer from 'common/Answer';
import Counter from 'common/Counter';
import Face from 'common/Face';
import Name from 'common/Name';
import Question from 'common/Question';
import Title from 'common/Title';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { quizes } from 'services/data';
import { handleAnswer } from 'store/app/action';
import { RootState } from 'types/store';
import styles from './Screen.module.scss';

const componentMap = {
  fullName: Name,
  image: Face,
  name: Name,
  slack: Name,
};

const bindedKeysMap = [
  ['1', 'q', 'a', 'z'],
  ['2', 'w', 's', 'x'],
  ['3', 'e', 'd', 'c'],
  ['4', 'r', 'f', 'v'],
  ['5', 't', 'g', 'b'],
];

const Screen: React.FC = () => {
  const [questionTime, setQuestionTime] = useState(Date.now());
  const [timeUp, setTimeUp] = useState(false);
  const dispatch = useDispatch();

  const options = useSelector((state: RootState) => state.app.options);
  const question = useSelector((state: RootState) => state.app.question);
  const revealed = useSelector((state: RootState) => state.app.revealed);
  const quizId = useSelector((state: RootState) => state.app.quizId);
  const roundTime = useSelector((state: RootState) => state.app.roundTime);

  const timeoutRef = useRef(0);

  const handleTimeout = useCallback(() => {
    dispatch(handleAnswer(false));
    setTimeUp(true);
  }, [dispatch]);

  const setAnswerTimeout = useCallback(() => {
    timeoutRef.current = window.setTimeout(handleTimeout, roundTime);
  }, [handleTimeout, roundTime]);

  const unsetAnswerTimeout = () => {
    window.clearTimeout(timeoutRef.current);
    timeoutRef.current = 0;
  };

  useEffect(() => {
    if (!revealed) {
      setAnswerTimeout();
      setQuestionTime(Date.now());
      setTimeUp(false);
    }

    return unsetAnswerTimeout;
  }, [revealed, setAnswerTimeout]);

  const quiz = quizes[quizId];
  const QuestionsComponent = componentMap[quiz.questions];
  const AnswersComponent = componentMap[quiz.answers];

  return (
    <div className={styles.container}>
      <Title label={quiz.title} noHeader={true} />

      <Question questionTime={questionTime}>
        <QuestionsComponent memberId={question} type="questions" />
      </Question>

      <div
        className={classnames(styles.answers, {
          [styles.horizontal]: quiz.answers === 'image',
        })}
      >
        {options.map((memberId, index) => (
          <Answer
            key={memberId}
            correct={memberId === question}
            timeUp={timeUp}
            bindedKeys={bindedKeysMap[index]}
          >
            <AnswersComponent memberId={memberId} type="answers" />
          </Answer>
        ))}
      </div>
      <Counter questionTime={questionTime} />
    </div>
  );
};

export default Screen;
