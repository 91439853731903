import { MAX_OPTIONS, MIN_OPTIONS, NEXT_QUESTION_TIME } from 'config';
import { quizes, teamByIds, teamIds } from 'services/data';
import { Mode } from 'types/game';
import { GetState, ReduxAction, ThunkDispatch } from 'types/store';
import { TeamMemberId } from 'types/team';
import { shuffle } from 'utils/array';
import { AppActionType } from './types';

export const answerSuccess = (): ReduxAction => ({
  type: AppActionType.APP_ANSWER_SUCCESS,
});

export const answerFailed = (): ReduxAction => ({
  type: AppActionType.APP_ANSWER_FAILED,
});

export const questionStart = (
  question: TeamMemberId,
  options: TeamMemberId[],
  quizId: number,
  roundTime: number
): ReduxAction => ({
  payload: {
    options,
    question,
    quizId,
    roundTime,
  },
  type: AppActionType.APP_QUESTION_START,
});

export const scoreAdd = (score: number): ReduxAction => ({
  payload: { score },
  type: AppActionType.APP_SCORE_ADD,
});

export const playerSet = (player: TeamMemberId): ReduxAction => {
  window.localStorage.setItem('player', player);
  return {
    payload: { player },
    type: AppActionType.APP_PLAYER_SET,
  };
};

export const playerReset = (): ReduxAction => {
  window.localStorage.removeItem('player');
  return {
    type: AppActionType.APP_PLAYER_RESET,
  };
};

export const queueReset = (): ReduxAction => ({
  type: AppActionType.APP_QUEUE_RESET,
});

export const gameOver = (): ReduxAction => ({
  type: AppActionType.APP_GAME_OVER,
});

export const gameReset = (): ReduxAction => ({
  type: AppActionType.APP_GAME_RESET,
});

export const handleAnswer = (correct: boolean) => (
  dispatch: ThunkDispatch
): void => {
  dispatch(correct ? answerSuccess() : answerFailed());
  setTimeout(() => dispatch(handleQuestionStart()), NEXT_QUESTION_TIME);
};

export const handleGameRestart = () => (
  dispatch: ThunkDispatch,
  getState: GetState
): void => {
  dispatch(gameReset());
  dispatch(handleQuestionStart());
};

export const handleQuestionStart = () => (
  dispatch: ThunkDispatch,
  getState: GetState
): void => {
  const { queue, quizId, roundTime, mode, lives, player } = getState().app;

  if (lives <= 0) {
    dispatch(gameOver());
    return;
  }

  const newRoundTime =
    mode === Mode.SUCCESS ? Math.max(roundTime * 0.95, 500) : roundTime;

  if (queue.length === 0) {
    dispatch(queueReset());
    dispatch(handleQuestionStart());
    return;
  }

  let options: TeamMemberId[] = [];
  let nextQuizId = quizId;
  let nextMemberId = queue.length - 1;
  let trials = 0;

  while (trials < quizes.length && options.length < MIN_OPTIONS) {
    if (nextMemberId === queue.length - 1) {
      trials++;
      nextMemberId = 0;
      nextQuizId = (nextQuizId + 1) % quizes.length;
    } else {
      nextMemberId++;
    }
    options = gatherOptions(queue[nextMemberId], nextQuizId, player);
  }

  if (trials >= quizes.length) {
    dispatch(queueReset());
    dispatch(handleQuestionStart());
    return;
  }

  dispatch(
    questionStart(queue[nextMemberId], options, nextQuizId, newRoundTime)
  );
};

const gatherOptions = (
  question: TeamMemberId,
  quizId: number,
  player?: TeamMemberId
): TeamMemberId[] => {
  const { group, unique } = quizes[quizId];

  const queue = shuffle(teamIds)
    .filter(member => member !== player)
    .filter(member => member !== question)
    .filter(
      member =>
        !group || teamByIds[member][group] === teamByIds[question][group]
    )
    .filter(
      (member, index, all) =>
        !unique ||
        (all.findIndex(
          duplicate =>
            teamByIds[duplicate][unique] === teamByIds[member][unique]
        ) === index &&
          teamByIds[member][unique] !== teamByIds[question][unique])
    );

  return shuffle([question, ...queue.slice(0, MAX_OPTIONS - 1)]);
};
