import classnames from 'classnames';
import Bar from 'common/Bar';
import Face from 'common/Face';
import { LIVES } from 'config';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { scoreAdd } from 'store/app/action';
import { Some } from 'ts-matches';
import { Mode } from 'types/game';
import { RootState } from 'types/store';
import Clock from './Clock';
import styles from './Counter.module.scss';

interface Props {
  questionTime: number;
}

const Counter: React.FC<Props> = ({ questionTime }) => {
  const dispatch = useDispatch();
  const [counter, setCounter] = useState(1);
  const score = useSelector((state: RootState) => state.app.score);
  const mode = useSelector((state: RootState) => state.app.mode);
  const revealed = useSelector((state: RootState) => state.app.revealed);
  const roundTime = useSelector((state: RootState) => state.app.roundTime);
  const lives = useSelector((state: RootState) => state.app.lives);
  const player = useSelector((state: RootState) => state.app.player);
  const revealedRef = useRef(revealed);
  const questionTimeRef = useRef(questionTime);
  const counterRef = useRef(1);

  const handleAnimation = useCallback(() => {
    const amount = 1 - (Date.now() - questionTimeRef.current) / roundTime;
    const truncated = Math.max(0, amount);
    setCounter(truncated);
    counterRef.current = truncated;

    if (!revealedRef.current) {
      window.requestAnimationFrame(handleAnimation);
    }
  }, [roundTime]);

  useEffect(() => {
    revealedRef.current = revealed;
    questionTimeRef.current = questionTime;

    if (!revealed) {
      handleAnimation();
      return;
    }
  }, [questionTime, revealed, dispatch, handleAnimation]);

  useEffect(() => {
    if (mode === Mode.SUCCESS) {
      dispatch(scoreAdd(counterRef.current));
    }
  }, [dispatch, mode]);

  return (
    <div className={styles.container}>
      <div className={classnames(styles.label)}>
        {Some.of(player)
          .map(some => <Face key="player" memberId={some} type="players" />)
          .defaultTo(<></>)}
      </div>
      <div className={styles.bars}>
        <Bar
          value={counter}
          key="timer"
          role="timer"
          pointsAnimation={revealed && mode === Mode.SUCCESS}
        />
        <Bar value={lives / LIVES} animated={true} key="energy" role="energy" />
      </div>
      <div className={styles.label}>
        <Clock value={score} />
      </div>
    </div>
  );
};

export default Counter;
